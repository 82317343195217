import { v1 as uuidv1 } from "uuid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
    createLineItem,
    createOrder,
    deleteLineItem,
    getMyOrders,
    updateLineItem,
} from "../orders/client";
import { addMessage } from "../alerts/messagesSlice";
import { orderKeys } from "../orders/hooks";

const useCart = () => {
    return useQuery(orderKeys.list({ status: `pending` }), () =>
        getMyOrders({ status: `pending` })
    );
};
const useAddToCart = ({ product }) => {
    const dispatch = useDispatch();
    const lineItemMutation = useCreateLineItem();
    // Create order
    return useMutation(createOrder, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed adding to cart: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            // Create line item
            lineItemMutation.mutate({
                imageUrl: product.imageUrl,
                name: product.name,
                orderId: data.id,
                quantity: 1,
                skuCode: product.skuCode,
            });
        },
    });
};
const useCreateLineItem = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(createLineItem, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed creating line item: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.all).then();
        },
    });
};
const useUpdateLineItem = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(updateLineItem, {
        onError: (error) => {
            const errors = JSON.parse(error.message).errors;
            if (errors[0].code === `VALIDATION_ERROR`) {
                dispatch(
                    addMessage({
                        id: uuidv1(),
                        text: `Max quantity available: ${errors[0].meta.count}`,
                        priority: `low`,
                    })
                );
            } else {
                dispatch(
                    addMessage({
                        id: uuidv1(),
                        text: `Failed updating line item: ${error}`,
                        priority: `low`,
                    })
                );
            }
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.all).then();
        },
    });
};
const useDeleteLineItem = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(deleteLineItem, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed deleting line item: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(orderKeys.all).then();
        },
    });
};

export {
    useCart,
    useAddToCart,
    useCreateLineItem,
    useUpdateLineItem,
    useDeleteLineItem,
};
