import { useQuery } from "react-query";
import PropTypes from "prop-types";
import cardAvailabilityLookup from "../../utils/card-availability-lookup";
import { directusClient } from "../../hooks/useDirectus";

const cardFields = [
    `id`,
    `name`,
    `subtitle`,
    `url_title`,
    `type`,
    `reference`,
    `rarity`,
    `image_url`,
    `available_standard`,
    `available_foiled`,
    `available_reverse_foiled`,
    `available_signed`,
    `languages_override`,
    `set.id`,
    `set.name`,
    `set.url_title`,
    `set.series.id`,
    `set.series.name`,
    `set.series.url_title`,
    `set.series.game.id`,
    `set.series.game.name`,
    `set.series.game.url_title`,
    `set.series.game.available_standard`,
    `set.series.game.available_foiled`,
    `set.series.game.available_reverse_foiled`,
    `set.series.game.available_signed`,
    `set.series.game.languages`,
    `set.series.game.ref`,
    `image.data.asset_url`,
];

export function useCardCount(setId) {
    return useQuery([`cardCount`, setId], () => getCardCount(setId), {
        staleTime: 86400000,
    });
}
export function useCardRarities() {
    return useQuery([`cardRarities`], () => getCardRarities(), {
        staleTime: 86400000,
    });
}
export function useCards(cardIds) {
    return useQuery([`cards`, cardIds], () => getCards(cardIds), {
        staleTime: 86400000,
    });
}
export function useCard(cardId) {
    return useQuery({
        queryKey: [`cards`, cardId],
        queryFn: () => getCard(cardId),
        staleTime: 86400000,
        select: (data) => ({
            data: {
                ...data.data,
                cardId: data.data.id,
                availableStandard: cardAvailabilityLookup(
                    data.data.available_standard,
                    data.data.set.series.game.available_standard
                ),
                availableFoiled: cardAvailabilityLookup(
                    data.data.available_foiled,
                    data.data.set.series.game.available_foiled
                ),
                availableReverseFoiled: cardAvailabilityLookup(
                    data.data.available_reverse_foiled,
                    data.data.set.series.game.available_reverse_foiled
                ),
                availableSigned: cardAvailabilityLookup(
                    data.data.available_signed,
                    data.data.set.series.game.available_signed
                ),
                availableLanguages:
                    data.data.languages_override.length > 0
                        ? data.data.languages_override
                        : data.data.set.series.game.languages,
                isVirtualCard: data.data.rarity && data.data.rarity.id === 3,
            },
        }),
    });
}

function getCardCount(setId) {
    // Need a faster endpoint. Simpler one with Content-Range? http://otac0n.com/blog/2012/11/21/range-header-i-choose-you.html
    return directusClient.get(`/items/card`, {
        meta: [`result_count`],
        fields: [`id`],
        filter: { set: { eq: setId } },
        limit: 99999,
    });
}
function getCardRarities() {
    return directusClient.get(`/items/rarities`, {}).then((res) => {
        const keyedRarities = {};
        res.data.forEach(
            (rarity) =>
                (keyedRarities[rarity.id] = {
                    name: rarity.name,
                    colour: rarity.colour,
                })
        );
        return keyedRarities;
    });
}
export function getCards(cardIds) {
    return directusClient.get(`/items/card`, {
        fields: cardFields,
        filter: { id: { in: cardIds } },
        limit: 99999,
    });
}
export function getCard(cardId) {
    return directusClient.get(`/items/card/${cardId}`, {
        fields: cardFields,
    });
}

getCardCount.propTypes = {
    setId: PropTypes.number.isRequired,
};
useCardCount.propTypes = {
    setIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export { cardFields };
