const cardAvailabilityLookup = (value, inheritedValue) => {
    switch (value) {
        case `I`:
            return inheritedValue;
        case `Y`:
            return true;
        case `N`:
            return false;
        default:
            return true;
    }
};
export default cardAvailabilityLookup;
