import * as React from "react";
import PropTypes from "prop-types";
import placeholderCard from "../../images/ui/placeholder-card.png";

export const CardImage = ({
    alt,
    assetUrl,
    className,
    fullUrl,
    height,
    width,
}) => {
    if (fullUrl) {
        return (
            <img
                alt={alt}
                className={className}
                height={height}
                loading="lazy"
                src={fullUrl.replace(`_hires`, ``)}
                width={width}
            />
        );
    }
    if (assetUrl) {
        return (
            <img
                alt={alt}
                className={className}
                height={height}
                loading="lazy"
                src={`${process.env.GATSBY_API_URL}${assetUrl}?key=card-medium`}
                width={width}
            />
        );
    }
    return (
        <img
            alt={alt}
            className={className}
            height={height}
            loading="lazy"
            src={placeholderCard}
            width={width}
        />
    );
};

CardImage.propTypes = {
    alt: PropTypes.string.isRequired,
    assetUrl: PropTypes.string,
    className: PropTypes.string,
    fullUrl: PropTypes.string,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
};
