import * as React from "react";
import { Link } from "gatsby";

import { LoginRegisterDialog } from "../features/account/LoginRegisterDialog";

export const Footer = ({ className = `` }) => {
    return (
        <footer
            className={`flex justify-between p-4 md:p-8 text-sm text-white ${className}`}
        >
            <nav className="">
                <p className="my-2">
                    <Link
                        className="opacity-50 focus:opacity-100 hover:opacity-100"
                        to="/about-us"
                    >
                        About Us
                    </Link>
                </p>
                <p className="my-2">
                    <Link
                        className="opacity-50 focus:opacity-100 hover:opacity-100"
                        to="/terms"
                    >
                        Terms and Conditions
                    </Link>
                </p>
                <p className="my-2">
                    <Link
                        className="opacity-50 focus:opacity-100 hover:opacity-100"
                        to="/privacy"
                    >
                        Privacy Policy
                    </Link>
                </p>
                <p className="opacity-50">
                    &copy; Copyright {new Date().getFullYear()}, CCG Trader
                </p>
                <p className="my-2">
                    <a
                        className="opacity-50 focus:opacity-100 hover:opacity-100"
                        href="https://westhouseit.co.uk"
                    >
                        Carefully crafted by Westhouse IT
                    </a>
                </p>
            </nav>
            <LoginRegisterDialog />
        </footer>
    );
};
